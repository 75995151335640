<template>
  <v-app class="add--vehicle">
    <v-dialog v-model="dialogThirdParty" persistent width="500px">
      <v-card>
        <v-container class="text-center">
          <v-img
            src="@/assets/img/thirdparty.png"
            class="mx-auto my-5"
            width="170"
          />
          <v-card-title>
            <h3 class="mx-auto primary--text">Sorry !</h3>
          </v-card-title>
          <v-card-text>
            <p>
              {{ expiredPolicy.response }}
            </p>
          </v-card-text>
          <v-card-actions class="vehicle--search">
            <v-btn
              class="secondary mx-auto my-5 px-12"
              large
              @click="$emit('updateThirdPartyDialogStatus', false)"
            >
              okay
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  props: {
    dialogThirdParty: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    expiredPolicy: {
      response:
        'Vehicle has a Third Party Cover which does not meet our qualifications for assessment, please contact the office for more details.',
    },
  }),
}
</script>
<style scoped>
.add--vehicle {
  height: 0px;
  float: right;
}
</style>
